import { Checkbox } from "antd";
import {
  Chargebee_PLAN_NAME_MONTHLY,
  Chargebee_PLAN_NAME_MONTHLY_OLD,
  Chargebee_PLAN_NAME_YEARLY,
  Chargebee_PLAN_NAME_YEARLY_OLD,
  VOICE_CHARGEBEE_PLAN_MONTHLY,
  VOICE_CHARGEBEE_PLAN_YEARLY,
  VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_MONTHLY,
  VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_YEARLY,
} from "../../constants/AppConstants";
import { useMemo } from "react";

export const voiceAgentStatus = {
  ONLINE_WEB: 1,
  ONLINE_PHONE: 2,
  ON_CALL: 3,
  AFTER_CALL_PROCESS: 4,
  BUSY: 5,
  OFFLINE: 6,
};

export const AUTOMATED_MESSAGE_TYPE = {
  URL: 1,
  FILE: 2,
  TEXT: 3,
};

export const voicePermissionOptions = [
  { label: "All", value: 1 },
  { label: "Inbound", value: 2 },
  { label: "Outbound", value: 3 },
];
const getPlanType = (planName) => {
  if (
    [Chargebee_PLAN_NAME_MONTHLY, Chargebee_PLAN_NAME_YEARLY, Chargebee_PLAN_NAME_MONTHLY_OLD, Chargebee_PLAN_NAME_YEARLY_OLD].includes(planName)
  ) {
    return "Message";
  }
  if ([VOICE_CHARGEBEE_PLAN_MONTHLY, VOICE_CHARGEBEE_PLAN_YEARLY].includes(planName)) {
    return "Call";
  }
  if ([VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_MONTHLY, VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_YEARLY].includes(planName)) {
    return "Message and Call";
  }
  return "";
};

// export const getPlanSwitchType = (currentPlanName, switchingToPlanName) => {
//   if ([Chargebee_PLAN_NAME_MONTHLY, Chargebee_PLAN_NAME_YEARLY].includes(currentPlanName)) {
//     if ([Chargebee_PLAN_NAME_MONTHLY, Chargebee_PLAN_NAME_YEARLY].includes(switchingToPlanName)) {
//       return 1; //info
//     } else if ([VOICE_CHARGEBEE_PLAN_MONTHLY, VOICE_CHARGEBEE_PLAN_YEARLY].includes(switchingToPlanName)) {
//       return 2; //downgrade
//     } else if ([VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_MONTHLY, VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_YEARLY].includes(switchingToPlanName)) {
//       return 3; //upgrade
//     }
//   } else if ([VOICE_CHARGEBEE_PLAN_MONTHLY, VOICE_CHARGEBEE_PLAN_YEARLY].includes(currentPlanName)) {
//     if ([Chargebee_PLAN_NAME_MONTHLY, Chargebee_PLAN_NAME_YEARLY].includes(switchingToPlanName)) {
//       return 2;
//     } else if ([VOICE_CHARGEBEE_PLAN_MONTHLY, VOICE_CHARGEBEE_PLAN_YEARLY].includes(switchingToPlanName)) {
//       return 1;
//     } else if ([VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_MONTHLY, VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_YEARLY].includes(switchingToPlanName)) {
//       return 3;
//     }
//   } else if ([VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_MONTHLY, VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_YEARLY].includes(currentPlanName)) {
//     if (
//       [Chargebee_PLAN_NAME_MONTHLY, Chargebee_PLAN_NAME_YEARLY, VOICE_CHARGEBEE_PLAN_MONTHLY, VOICE_CHARGEBEE_PLAN_YEARLY].includes(
//         switchingToPlanName
//       )
//     ) {
//       return 2;
//     } else if ([VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_MONTHLY, VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_YEARLY].includes(switchingToPlanName)) {
//       return 1;
//     }
//   }
//   return 0;
// };

const PLAN_CATEGORIES = {
  MESSAGE: [Chargebee_PLAN_NAME_MONTHLY, Chargebee_PLAN_NAME_YEARLY],
  VOICE: [VOICE_CHARGEBEE_PLAN_MONTHLY, VOICE_CHARGEBEE_PLAN_YEARLY],
  VOICE_PLUS_MESSAGE: [VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_MONTHLY, VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_YEARLY],
};

const PLAN_SWITCH_MATRIX = {
  MESSAGE: {
    MESSAGE: 1, // Info
    VOICE: 2, // Downgrade
    VOICE_PLUS_MESSAGE: 3, // Upgrade
  },
  VOICE: {
    MESSAGE: 2, // Downgrade
    VOICE: 1, // Info
    VOICE_PLUS_MESSAGE: 3, // Upgrade
  },
  VOICE_PLUS_MESSAGE: {
    MESSAGE: 2, // Downgrade
    VOICE: 2, // Downgrade
    VOICE_PLUS_MESSAGE: 1, // Info
  },
};

export const getPlanSwitchType = (currentPlanName, switchingToPlanName) => {
  const getCurrentPlanCategory = (planName) => {
    return Object.entries(PLAN_CATEGORIES).find(([_, plans]) => plans.includes(planName))?.[0] || "MESSAGE";
  };

  const currentCategory = getCurrentPlanCategory(currentPlanName);
  const switchCategory = getCurrentPlanCategory(switchingToPlanName);

  return PLAN_SWITCH_MATRIX[currentCategory]?.[switchCategory] || 0;
};

export const getCurrentFeature = (appProperties) => {
  return getPlanType(appProperties?.licenseObj?.licenseDetails?.planName);
};

export const useVoiceIntegId = (appProperties) => {
  return useMemo(() => {
    const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
    return voiceIntegrations?.[0]?.integProps?.integId;
  }, [appProperties]);
};

export const getCurrentPlanName = (planName) => {
  return getPlanType(planName);
};

export const getCheckBox = (props) => {
  const { currentCheckedState, onChange, options } = props;

  return (
    <div className="d-flex mt-2 gap-3">
      {options?.map((option) => {
        return (
          <Checkbox
            key={option.value}
            className="schedule-check-box hs-fs-16"
            value={option.value}
            onChange={(e) => {
              onChange(e);
            }}
            checked={currentCheckedState === option.value}
          >
            {option.label}
          </Checkbox>
        );
      })}
    </div>
  );
};

export const initiateOutboundCall = (toNumber, associatedObjectId, associatedObjectType, appProperties, setAppProperties) => {
  console.log("CTC initiateOutboundCall >>>>>>>>>>", toNumber, appProperties, setAppProperties);
  if (toNumber && appProperties && setAppProperties) {
    const clearExitingCallProperties = () => {
      setAppProperties((prev) => {
        return {
          ...prev,
          callerNumber: "",
          callRecordId: "",
          callRecordModule: "",
          callType: "",
        };
      });
    };
    clearExitingCallProperties();
    setAppProperties((prev) => {
      console.log("CTC clearExitingCallProperties >>>>>>", prev);
      return {
        ...prev,
        callerNumber: toNumber,
        callRecordId: associatedObjectId,
        callRecordModule: associatedObjectType,
        callType: "outbound",
      };
    });
    appProperties?.controller?.showCallNotification(appProperties?.loadedSDK, "outbound", setAppProperties);
  }
};
