import { Col, Layout } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuFoldOutlined, PushpinOutlined } from "@ant-design/icons";
import "../../assets/css/omessage.css";
import { HOMEPAGE_URL, navigateToPathWithCurrentParams } from "../../pages/utils/commonUtils";
import { urlParams } from "./../../constants/AppConstants";
import SideBarMenuItem from "./SideBarItemComponents/SideBarMenuItem";

const { Sider } = Layout;

function Sidebar(props) {
  const { toggleSidebar, isPinned, togglePin, handleNavigation, isFloating } = props;
  let sideBarNeeded = urlParams.get("sideBar");
  const navigate = useNavigate();
  let path = window.location.pathname;
  if (path === "/zoho-crm/webhook") {
    sideBarNeeded = "false";
  }
  var sideBarFalse = "block";
  if (path === "/zoho-crm/landing" || path === "/teams/landing") {
    sideBarFalse = "d-none";
  }
  const [isSideBarNeeded] = useState(sideBarNeeded === undefined || sideBarNeeded !== "false");

  return (
    <>
      {isSideBarNeeded && (
        <Sider
          theme="light"
          className={`menuOmessage omessageSider overflow-y-auto ${sideBarFalse}`}
          style={{
            height: "100dvh",
            top: 0,
            bottom: 0,
            zIndex: 1,
            background: "white",
            borderInlineEnd: "1px solid rgb(230, 231, 236)",
          }}
        >
          <div className="demo-logo-vertical" />
          <div className="d-flex justify-content-between align-items-center">
            <div
              key="logo"
              style={{ height: "100px" }}
              title=""
              className="hellosendHover"
              onClick={() => {
                navigateToPathWithCurrentParams(HOMEPAGE_URL, navigate);
                handleNavigation(); // Close sidebar if in floating mode
              }}
            >
              <div className="d-flex justify-content-left ps-4">
                <Col className="mt-3">
                  <div className="actionIconsSprite hellosendLogo" title=""></div>
                </Col>
              </div>
            </div>
            <div className="d-flex">
              {/* Pin/Unpin button */}
              <div 
                className={`sidebar-pin-btn ${isPinned ? 'pinned' : ''}`}
                onClick={togglePin}
                style={{
                  padding: '8px',
                  marginRight: '8px',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                title={isPinned ? "Unpin sidebar" : "Pin sidebar"}
              >
                <PushpinOutlined style={{ 
                  fontSize: '16px',
                  transform: isPinned ? 'rotate(-45deg)' : 'none',
                  color: isPinned ? '#1890ff' : 'inherit'
                }} />
              </div>
              
              {/* Collapse button */}
              {!isPinned && !isFloating &&
              <div 
                className="sidebar-collapse-btn" 
                onClick={toggleSidebar}
                style={{
                  padding: '8px',
                  marginRight: '8px',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <MenuFoldOutlined style={{ fontSize: '16px' }} />
              </div>
              }
            </div>
          </div>
          <SideBarMenuItem 
            toggleSidebar={toggleSidebar} 
            sideBarCommonHandling={false} 
            handleNavigation={handleNavigation}
          />
        </Sider>
      )}
    </>
  );
}

export default Sidebar;
