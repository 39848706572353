import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Card, Typography, Skeleton, Tag, Space, Button, Tooltip, Spin, message } from "antd";
import { CopyOutlined, CheckOutlined, CalendarOutlined, LinkOutlined } from "@ant-design/icons";
import { AppContext } from "../../context/AppContext";
import MessageLogs from "../MessageLogs";
import useHttp from "../../hooks/useHttp";
import dayjs from "dayjs";
import "./automationLogs.css"; // Will create this file if needed

const { Title, Text, Paragraph } = Typography;

/**
 * AutomationMessageLogs Component
 *
 * Displays message logs filtered by an automation event ID.
 * This page is accessed through the URL pattern: /messaging/automation/:eventId/logs
 */
const AutomationMessageLogs = () => {
  const { eventId } = useParams();
  const location = useLocation();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { fetchData } = useHttp();
  const [automationDetails, setAutomationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [webhookCopied, setWebhookCopied] = useState(false);

  // Set page title and scroll to top
  useEffect(() => {
    // Scroll to top of the page
    window.scrollTo(0, 0);
  }, [setAppProperties]);

  // Fetch automation details using v2 API endpoint
  useEffect(() => {
    if (eventId && appProperties?.randomIntegId) {
      setLoading(true);
      const webhookUrl = `omessage/${appProperties.randomIntegId}/webhook?eventId=${eventId}`;
      console.log("Fetching automation details for event ID:", eventId);
      console.log("API endpoint:", webhookUrl);

      // Prepare headers
      const headers = appProperties.headers || {};

      // Use the v2 API endpoint to fetch the specific webhook directly
      // Remove any duplicate api/v2 prefixes
      fetchData(webhookUrl, "GET", null, {
        ...appProperties,
        headers: {
          ...headers,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response) {
            const responseData = JSON.parse(response);
            if (responseData?.data?.webhookNotification) {
              setAutomationDetails({
                ...responseData.data.webhookNotification,
                eventUrl: responseData.data.webhookUrl,
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching automation details:", error);
          message.error("Failed to load automation details");
        })
        .finally(() => {
          // Use setTimeout to ensure this runs after any promises from the then block
          setTimeout(() => {
            setLoading(false);

            // Scroll to top after data is loaded and component is rendered
            // Add a small delay to ensure all content is properly rendered
            setTimeout(() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }, 100);
          }, 0);
        });
    }
  }, [eventId, appProperties, fetchData]);

  // Get the webhook URL from the different possible sources
  const getWebhookUrl = () => {
    if (automationDetails?.eventUrl) {
      return automationDetails.eventUrl;
    }
    return null;
  };

  // Handle copying webhook URL to clipboard
  const copyWebhookUrl = () => {
    const webhookUrl = getWebhookUrl();
    if (webhookUrl) {
      navigator.clipboard
        .writeText(webhookUrl)
        .then(() => {
          setWebhookCopied(true);
          message.success("Webhook URL copied to clipboard");
          setTimeout(() => setWebhookCopied(false), 3000);
        })
        .catch((err) => {
          console.error("Failed to copy webhook URL:", err);
          message.error("Failed to copy URL to clipboard");
        });
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    return dayjs(dateString).format("MMM DD, YYYY HH:mm A");
  };

  return (
    <div className="m-3 mt-1">
      <Card className="mb-1" loading={loading} bodyStyle={{ padding: "10px" }}>
        {loading ? (
          <Skeleton active paragraph={{ rows: 2 }} />
        ) : automationDetails ? (
          <div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-1">
              <div>
                <Title level={5} className="mb-1 mt-0">
                  {automationDetails.friendlyName || "Unnamed Automation"}
                </Title>
                {automationDetails.createdTime && (
                  <div className="d-flex align-items-center">
                    <CalendarOutlined style={{ marginRight: 8, color: "#8c8c8c" }} />
                    <Text type="secondary">Created: {formatDate(automationDetails.createdTime)}</Text>
                  </div>
                )}
              </div>

              <div className="mt-1 mt-md-0">
                <Space>
                  {automationDetails.event && <Tag color="blue">{automationDetails.event.split("_").reverse().join(" / ")}</Tag>}
                  {getWebhookUrl() && (
                    <Button
                      type="primary"
                      ghost
                      icon={webhookCopied ? <CheckOutlined /> : <CopyOutlined />}
                      onClick={copyWebhookUrl}
                      style={{
                        color: "#1890ff",
                        borderColor: "#1890ff",
                      }}
                    >
                      {webhookCopied ? "Copied" : "Copy Webhook URL"}
                    </Button>
                  )}
                </Space>
              </div>
            </div>

            {getWebhookUrl() && (
              <Paragraph
                className="p-2 bg-light d-flex align-items-center mb-0"
                style={{
                  wordBreak: "break-all",
                  fontSize: "12px",
                  color: "#666",
                  border: "1px solid #f0f0f0",
                  maxWidth: "100%",
                  overflow: "hidden",
                  background: "#f6f8fa",
                }}
              >
                <LinkOutlined style={{ marginRight: 8, color: "#1890ff", flexShrink: 0 }} />
                <code style={{ color: "#1890ff" }}>{getWebhookUrl()}</code>
              </Paragraph>
            )}
          </div>
        ) : (
          <div className="text-center py-3">
            <Text type="warning">No automation details available. Showing message logs for Webhook ID: {eventId}</Text>
          </div>
        )}
      </Card>

      {/* Render the MessageLogs component with the eventId as a preset filter */}
      <div className="message-logs-wrapper">
        <MessageLogs
          initialAutomationEventId={eventId}
          locationState={location.state}
          hideColumns={["sent_via"]}
          preventHorizontalScroll={true}
          filterConfig={{
            status: true,
            errorCode: false,
            receiver: true,
            sender: true,
            user: true,
            dateRange: true,
            tableSize: "440",
          }}
        />
      </div>
    </div>
  );
};

export default AutomationMessageLogs;
